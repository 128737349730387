export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Perfil',
    route: 'perfil',
    icon: 'UserIcon',
  },
  {
    title: 'Pre Alertas',
    route: 'pre-alertas',
    icon: 'AlertOctagonIcon',
  },
  {
    title: 'Destinatarios',
    route: 'destinatarios',
    icon: 'FileIcon',
  },
  {
    title: 'Historial',
    route: 'historial',
    icon: 'ActivityIcon',
  },
  {
    title: 'Despachos',
    route: 'despachos',
    icon: 'ArchiveIcon',
  },
]
