<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
             {{ $store.getters["clients/getFullName"] }}
            </p>
            <span class="user-status"><b>No Casillero:</b> {{ $store.getters["clients/getCasillero"] }} </span>
          </div>
          <b-avatar size="40" variant="light-primary">
            <span>Hola!</span>
          </b-avatar>
        </template>
        <b-dropdown-item link-class="d-flex align-items-center" @click="onPerfil">
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>Perfil</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="onLogout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Cerrar Sesión</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from "@/store";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onLogout() {
      this.$session.clear('jwt');
      this.$session.clear('rjwt');
      this.$session.destroy();
      this.$router.push('/');
    },
    onPerfil() {
      this.$router.push('/perfil');
    },
    loadInfoUser () {
      let str = "Bearer " + this.$session.get('userData').access_token
      this.$http.get('api/casillero/clientes', {
        headers: { Authorization: str }
      }).then( response => {
        store.dispatch('clients/updInfoUserAction', response.data.datos )
      }).catch( error => {
        console.log(error)
      })
    }
  },
  beforeMount() {
    this.loadInfoUser()
  }
}
</script>
